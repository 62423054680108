import axios, {AxiosRequestConfig} from "axios";

export async function call(url: string, method = "post", data = {}) {

    const encodeForm = (data: any) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&');
    }

    let config: AxiosRequestConfig = {
        // @ts-ignore
        method: method,
        url,
        data: encodeForm(data),
        transformRequest: (data, headers) => {
            delete headers.common['Content-Type']

            return data;
        }
    };

    return await axios(config).catch((error) => {
        console.log(error.response)
        console.log(error.response.data)
        throw error;
        // if (redirectableStatuses.includes(error.response.status)) {
        // 	checkForCommonFailures(error.response, '/');
        // } else {
        // 	throw error;
        // }
    });
}