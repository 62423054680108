// extracted by mini-css-extract-plugin
export var Action = "Intro-module--Action--TWkMf";
export var Benefits = "Intro-module--Benefits--b4NWt";
export var Content = "Intro-module--Content--9spXN";
export var Greeting = "Intro-module--Greeting--3wXjt";
export var Intro = "Intro-module--Intro--Jl-Yv";
export var Item = "Intro-module--Item--zoz4o";
export var Line = "Intro-module--Line--KAD2o";
export var Opener = "Intro-module--Opener--yajGe";
export var PlayCard = "Intro-module--PlayCard--BZY5Y";
export var Side = "Intro-module--Side--FqbHY";
export var Video = "Intro-module--Video--BMJ6i";